<template>
  <v-dialog v-model="helpDialog" width="800">
    <template v-slot:activator="{ on: dialogOn }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn v-on="{ ...dialogOn, ...tooltipOn }" icon>
            <v-icon color="info">fas fa-question-circle</v-icon>
          </v-btn>
        </template>
        <span>Help with the Jury Admin</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Help</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="helpDialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>Time Slots</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card-text>
              <p>Everything is based on 10-minute time slots. When you first load a term it will prompt to create the time slots for that term (it will look for the first reading day of the term, which means the term detail must already be set by the Records office, and then extend for 5 weekdays starting at that first reading day). The time slots are automatically created for those 5 days with blocks from 10am-12am and 1pm-3pm. Currently the system does not allow removing time slots or adding new time slots.</p>
              <p>Each time slot will have zero or more instructors and/or accompanists assigned to that slot. This is how students will look for their slots (selecting their instructor and optionally an accompanist, and the system will find any time slots that have both). You will need to add the instructors and accompanists to the system and then add the time slots for which they are available. See the next section on how to view the time slots for which instructors/accompanists are available and how to add new people.</p>
              <p>When a student reserves a time slot, the slot will turn green and will include the student's name. Clicking on that time slot will take you to the page where you can evaluate their performance. If you access a time slot for which you have already submitted an evaluation, it will open that evaluation to let you edit it. If you are their instructor, then it will allow you to view all of the evaluations and the option of creating a PDF output containing all of the evaluations combined.</p>
            </v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Instructors/Accompanists</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card-text>
              <p>The system allows you to filter the calendar to one or more instructors and one or more accompanists. To access this filter, click the filter icon (<v-icon>fal fa-filter</v-icon>) in the upper-right corner. This will display two dropdown boxes, one for Instructor Filter and one for Accompanist Filter. These dropdowns are multi-select so you can select more than one entry in each one. If you do that, it will include all timeslots that contain any of the selected instructors and any of the selected accompanists (so you can see overlap for specific instructor/accompanist combinations).</p>
              <p>To add a new instructor or accompanist, click on the dropdown and choose the last option (Add Instructor of Add Accompanist). This will open a popup where you can enter the name of the instructor or accompanist. Make sure you enter their name correctly so it will match up to their name in the system (enter the names as First Last, matching what is in the <a :to='/greatscots/'>directory</a>).</p>
            </v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Editing Available Times</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card-text>
              <p>To edit times for an instructor or accompanist, you must first filter the calendar to a single instructor or accompanist (See the Instructors/Accompanists section for how to filter). When a single person is selected, a button will appear in the toolbar (next to the Help button) saying "Edit Times for ..." with the name of the person selected. Click this button to begin adding and removing times for the person.</p>
              <p>To add new times, click on an open time slot on the calendar for the beginning of the time that you want to enter. This will create a small red event on the calendar spanning the single time slot. Click again on another time slot and it will expand the box to encompass both slots, and will turn it to Orange showing that they are ready to be added. If, for the second time selection, you click on a time slot before the first slot selected, then the system will set it to be just that one slot (can't go backwards).</p>
              <p>To remove an assigned time slot, click on the slot and it should change to red and say "Remove from ...".</p>
              <p>You can also click on added or removed times to undo that change, or click the "Cancel (Discard)" button in the toolbar to cancel all changes. This will reload all of the time slots from the server and undo any changes that are pending.</p>
              <p>When you are finished selecting the time periods to add or remove, click the "Finish (Save)" button in the toolbar. This will trigger a process where it will add or remove the instructor/accompanist from the time slots that overlap with the selected times. This should only take a few seconds.</p>
            </v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions>
        <v-btn text @click="helpDialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'

export default {
  setup () {
    const helpDialog = ref(false)

    return {
      helpDialog
    }
  }
}
</script>
